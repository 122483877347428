
import {useEffect, useState} from "react";
import {VerificationDTO, VerificationService} from "../../services/VerificationService";
import {FieldValidationError, ServerResponse} from "../../services/ServiceHelper";
import * as React from "react";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import VerificationCard from "./VerificationCard";
import './verify.css'

const VerifySendCode = ({ currentPhone, currentEmailAddy, setCurrentPhone, setCurrentEmailAddy, nextStep, setVerifyMethod, setResendAddress, embed }: { currentPhone: string | null, currentEmailAddy: string, setCurrentPhone: Function, setCurrentEmailAddy: Function, nextStep: Function, setVerifyMethod: Function, setResendAddress: Function, embed: boolean }) => {
    const [usePhoneVerification, setUsePhoneVerification] = useState<boolean>(false); // Default to phone again once the phone numbers work
    const [emailAddy, setEmailAddy] = useState<string>("");
    const [errors, setErrors] = useState<FieldValidationError[]>([]);
    const [intlPhone, setIntlPhone] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(true);

    useEffect(() => {
        if (currentEmailAddy) {
            setEmailAddy(currentEmailAddy);
        }

        if (currentPhone) {
            if (currentPhone.startsWith("+")) {
                setIntlPhone(currentPhone);
            } else {
                // Default the phone to US country code
                setIntlPhone("+1"+currentPhone);
            }
        }
    }, [currentPhone, currentEmailAddy]);

    const setInvalidPhone = () => {
        setErrors([{field: 'intlPhone', errors: ["The phone number is not valid for the country chosen. Please check it and try again."]}])
        return false;
    }

    const verifyPhone = async () => {
        setEnableSubmit(false);
        setErrors(errors => []);

        try {
            if (!isValidPhoneNumber(intlPhone)) {
                setInvalidPhone();
                setEnableSubmit(true);
                return;
            }
        } catch(e) {
            setInvalidPhone();
            setEnableSubmit(true);
            return;
        }

        const ve = await VerificationService.ensureVerificationExists();
        if (!ServerResponse.isSuccess<VerificationDTO>(ve)) {
            setErrors([{ field: 'intlPhone', errors: ["There was an error. Please try again!"] }]);
            setEnableSubmit(true);
            return;
        }

        const res = await VerificationService.sendVerificationCodeToPhone(intlPhone);
        if (ServerResponse.isSuccess<VerificationDTO>(res)) {
            setVerifyMethod("phone");
            setCurrentPhone(intlPhone);
            setResendAddress(intlPhone);
            nextStep();
            setEnableSubmit(true);
        } else {
            setErrors([{ field: 'intlPhone', errors: ["There was an error. Please try again!"] }]);
            setEnableSubmit(true);
            return;
        }
    }

    const isValidEmail = (emailToTest: string) => {
        try {
            if (!emailToTest) {
                return false;
            }

            if (emailToTest.length>254) {
                return false;
            }

            const parts = emailToTest.split("@");
            if (parts[0].length>64) {
                return false;
            }

            const domainParts = parts[1].split(".");
            if (domainParts.some(function(part) { return part.length>63; })) {
                return false;
            }

            const testRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

            const isValid = testRegex.test(emailToTest);
            if (!isValid) {
                return false;
            }

            return true;

        } catch {
            return false;
        }
    }

    const verifyEmailAddy = async () => {
        setEnableSubmit(false);
        setErrors(errors => []);

        if (!isValidEmail((emailAddy))) {
            setErrors([{ field: 'emailAddy', errors: ["Please check the email address and try again."] }]);
            setEnableSubmit(true);
            return false;
        }

        const ve = await VerificationService.ensureVerificationExists();
        if (!ServerResponse.isSuccess<VerificationDTO>(ve)) {
            setErrors([{ field: 'emailAddy', errors: ["There was an error. Please try again!"] }]);
            setEnableSubmit(true);
            return;
        }
        const res = await VerificationService.sendVerificationCodeToEmail(emailAddy);

        if (ServerResponse.isSuccess<VerificationDTO>(res)) {
            setVerifyMethod("email");
            setCurrentEmailAddy(emailAddy);
            setResendAddress(emailAddy);
            nextStep();
            setEnableSubmit(true);
        } else {
            setErrors([{ field: 'emailAddy', errors: ["There was an error. Please try again!"] }]);
            setEnableSubmit(true);
            return;
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!enableSubmit) {
            return;
        }

        if (usePhoneVerification) {
            if (currentPhone != "" &&  currentPhone != intlPhone && "+1"+currentPhone != intlPhone) {
                setShowModal(true);
            } else {
                await verifyPhone();
            }
        } else {
            if (currentEmailAddy != emailAddy) {
                setShowModal(true);
            } else {
                await verifyEmailAddy();
            }
        }
    }

    const handleModalClose = async (accepted: boolean) => {
        setShowModal(false);
        if (accepted) {
            if (usePhoneVerification) {
                await verifyPhone();
            } else {
                await verifyEmailAddy();
            }
        }
    };

    return (
        <VerificationCard title="Verify Your Account" embed={embed}>
            {showModal && <UpdateEmailOrPhoneModal mode={usePhoneVerification ? "phone" : "email"} closeModal={handleModalClose} embed={embed} />}

            <p>This account isn't verified yet. For your security, SermonCentral wants to verify your account.</p>
            {usePhoneVerification ? (
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="mb-3">
                            <label>Phone Number</label>

                            <PhoneInput smartCaret={true}  defaultCountry="US" value={intlPhone} onChange={(e) => setIntlPhone(e?.toString() ?? "")} className={`form-control-lg form-control ${FieldValidationError.isFieldInError(errors, 'intlPhone') ? 'is-invalid' : null}`}/>

                            {FieldValidationError.isFieldInError(errors, "intlPhone") ?
                                <div className="text-danger"
                                     dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "intlPhone") }}
                                /> : null
                            }
                        </div>

                        <button type="submit" className="btn btn-primary btn-block btn-lg mb-3" style={{backgroundColor: embed ? "#3b9d42" : "default"}} disabled={!enableSubmit}>
                            {enableSubmit && <>SEND</>}
                            {!enableSubmit &&
                                <>
                                    PLEASE WAIT...
                                    <div
                                        className="spinner-border spinner-border-sm ml-4 mb-1"
                                        role="status"
                                    />
                                </>
                            }
                        </button>

                        <button type="button" className="btn btn-link"
                            onClick={() => setUsePhoneVerification((!usePhoneVerification))}>More options
                        </button>
                    </div>
                </form>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <div className="mb-3">
                            <label>Email Address</label>
                            <div className="input-group">
                                <input type="email" className={`form-control-lg form-control ${FieldValidationError.isFieldInError(errors, 'emailAddy') ? 'is-invalid' : null}`}
                                       value={emailAddy}
                                       minLength={5}
                                       maxLength={50}
                                       onChange={(e) => setEmailAddy(e.target.value.trim())}
                                />
                            </div>
                            {FieldValidationError.isFieldInError(errors, "emailAddy") ?
                                <div className="text-danger"
                                     dangerouslySetInnerHTML={{ __html: FieldValidationError.getFieldErrorSummary(errors, "emailAddy") }}
                                /> : null
                            }
                        </div>
                        <button type="submit" className="btn btn-primary btn-block btn-lg mb-3" style={{backgroundColor: embed ? "#3b9d42" : "default"}} disabled={!enableSubmit}>
                            {enableSubmit && <>SEND</>}
                            {!enableSubmit &&
                                <>
                                    PLEASE WAIT...
                                    <div
                                        className="spinner-border spinner-border-sm ml-4 mb-1"
                                        role="status"
                                    />
                                </>
                            }
                        </button>
                    </div>
                </form>
            )
            }
        </VerificationCard>
    );
}

const UpdateEmailOrPhoneModal = ({ mode, closeModal, embed }: { mode: "phone" | "email", closeModal: (x: boolean) => void, embed: boolean}) => (
    <div className="custom-modal-background" onClick={() => closeModal(false)}>
        <div className="custom-modal" onClick={() => closeModal(false)}>
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {}}
            >
                <span aria-hidden="true">x</span>
            </button>
            <div className="custom-modal-body">
                <p>
                    The {mode === "phone" ? "phone number" : "email"} you're using to verify doesn't match the one listed on your account.
                    Proceeding will update your account to match the new contact information. Is this okay?
                </p>
            </div>
            <div className="custom-modal-footer">
                <button type="button" className="btn btn-secondary custom-modal-button" onClick={() => closeModal(false)}>Cancel</button>
                <button type="button" className="btn btn-primary custom-modal-button" style={{backgroundColor: embed ? "#3b9d42" : "default"}} onClick={() => closeModal(true)}>Update & Verify</button>
            </div>
        </div>
    </div>
);

export default VerifySendCode;