import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationDescriptor } from "history";
import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import AppConfig from "../../entities/AppConfig";
import ApplicationUser from "../../entities/ApplicationUser";
import Country from "../../entities/Country";
import CreditCard from "../../entities/CreditCard";
import { CreditCardType } from "../../entities/CreditCardType";
import PostalCodeLocation from "../../entities/PostalCodeLocation";
import ProPlan from "../../entities/ProPlan";
import {
  ProSubscriptionStatus,
  ProSubscriptionTerm,
  ProSubscriptionTier,
} from "../../entities/ProSubscription";
import SSLLogo from "../../resources/ssl.png";
import { CreditCardService } from "../../services/CreditCardService";
import { PostalCodeService } from "../../services/PostalCodeService";
import { ProSubscriptionService } from "../../services/ProSubscriptionService";
import {
  FieldValidationError,
  ServerModelValidationResponse,
  ServerResponse,
} from "../../services/ServiceHelper";

interface Props extends RouteComponentProps<any> {
  user: ApplicationUser;
  config: AppConfig;
  handleUpdateUser: (user: ApplicationUser | null) => void;
  hasPaypal: boolean;
}

type State = {
  loading: boolean;
  redirectTo: LocationDescriptor<any> | null;
  errors: FieldValidationError[];
  existingCreditCards: CreditCard[];
  selectedCreditCardId: string;
  showUseNewCardForm: boolean;
  nameOnCard: string;
  creditCardNumber: string;
  cv2: string;
  expirationMonth: number;
  expirationYear: number;
  postalCode: string;
  creditCardType?: CreditCardType;
  enableSubmit: boolean;
  proPlans: ProPlan[];
  selectedProPlanId: string;
  upgradeToPremiumCost?: number;
  upgradeToPlusCost?: undefined | null | number;
  biennialUser: boolean;
  countries: Country[];
  showCountries: boolean;
  validatedPostalCodeLocation?: PostalCodeLocation;
  selectedCountryShortCode?: string;
};

export default class UpgradeSubscriptionTier extends React.Component<
  Props,
  State
> {
  redirectState: any = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      redirectTo: null,
      errors: [],
      existingCreditCards: [],
      selectedCreditCardId: "",
      showUseNewCardForm: false,
      nameOnCard: "",
      creditCardNumber: "",
      cv2: "",
      expirationMonth: new Date().getMonth() + 1,
      expirationYear: new Date().getFullYear(),
      postalCode: "",
      enableSubmit: true,
      proPlans: [],
      selectedProPlanId: "",
      biennialUser: false,
      countries: [],
      showCountries: false,
    };
  }

  async componentDidMount() {
    document.title = "SermonCentral Payments - Upgrade Trial Subscription";

    let currency = this.props.user.currency;

    if (this.props.hasPaypal) {
      (this.redirectState as any).previousLocation = "Upgrade Tier";
      this.setState({
        redirectTo: {
          pathname: "/feature-unavailable",
          state: this.redirectState,
        },
      });
    }
    const subscription = this.props.user.proSubscription;

    if (!subscription || subscription.inTrial) {
      this.setState({
        redirectTo: { pathname: "/upgrade", state: this.redirectState },
      });
    } else if (subscription.term === ProSubscriptionTerm.None) {
      this.setState({
        redirectTo: { pathname: "/upgrade", state: this.redirectState },
      });
    } else if (subscription.status !== ProSubscriptionStatus.Active) {
      this.setState({
        redirectTo: { pathname: "/upgrade", state: this.redirectState },
      });
    } else if (subscription.tier === ProSubscriptionTier.Premium) {
      this.setState({
        redirectTo: { pathname: "/upgrade", state: this.redirectState },
      });
    } else if (
      subscription.tier === ProSubscriptionTier.Plus &&
      subscription.term === ProSubscriptionTerm.Biennial
    ) {
      this.setState({ biennialUser: true, loading: false });
    } else {
      let redirectState = this.props.location.state || {};

      const responses = await Promise.all([
        ProSubscriptionService.getProPlans(),
        ProSubscriptionService.getCountries(),
        CreditCardService.getCreditCards(),
      ]);

      const [proPlansResult, locationResult, creditCardsResult] = responses;

      this.setState({ loading: false });

      if (ServerResponse.isError(proPlansResult) || !proPlansResult) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve plans. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        let plans = [...proPlansResult];

        var userProPlan = plans.find(
          (p) => p.term === subscription.term && p.tier === subscription.tier
        )!;

        if (
          (userProPlan.term === ProSubscriptionTerm.Monthly &&
            userProPlan.tier === ProSubscriptionTier.Plus) ||
          userProPlan.tier === ProSubscriptionTier.Basic
        ) {
          plans = plans.filter(
            (p) => p.id === "AnnualPlus" || p.id === "AnnualPremium"
          );
        } else if (
          (userProPlan.term === ProSubscriptionTerm.Annual ||
            userProPlan.term === ProSubscriptionTerm.Biennial) &&
          userProPlan.tier === ProSubscriptionTier.Plus
        ) {
          plans = plans.filter((p) => p.id === "AnnualPremium");
        } else {
          this.setState({
            redirectTo: { pathname: "/upgrade", state: this.redirectState },
          });
        }

        var selectedProPlanId = "AnnualPremium";
        if (
          (redirectState as any).plan === "plus" &&
          plans.find((p) => p.id === "AnnualPlus")
        ) {
          selectedProPlanId = "AnnualPlus";
        }

        this.setState({
          selectedProPlanId: selectedProPlanId,
          proPlans: plans,
        });
      }

      if (ServerResponse.isError(locationResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve location. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else if (ServerResponse.isSuccess<Country[]>(locationResult)) {
        this.setState({
          countries: locationResult.data,
        });
      }

      if (ServerResponse.isError(creditCardsResult)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["Unable to retrieve credit cards. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        if (creditCardsResult && creditCardsResult.length > 0) {
          var defaultCard = creditCardsResult.find((cc) => cc.useCardByDefault);
          this.setState({
            existingCreditCards: creditCardsResult,
            selectedCreditCardId: defaultCard
              ? defaultCard.id
              : creditCardsResult[0].id,
          });
        }
      }

      CreditCardService.getCreditCards().then((creditCards) => {
        if (creditCards && creditCards.length > 0) {
          var defaultCard = creditCards.find((cc) => cc.useCardByDefault);
          this.setState({
            existingCreditCards: creditCards,
            selectedCreditCardId: defaultCard
              ? defaultCard.id
              : creditCards[0].id,
            loading: false,
          });
        } else {
          this.setState({ loading: false });
        }
      });

      ProSubscriptionService.getUpgradeToProPremiumCost().then(
        async (upgradeToPremiumCost) => {
          this.setState({ upgradeToPremiumCost });
          const response =
            await ProSubscriptionService.getUpgradeToProPlusCost();
          if (ServerResponse.isSuccess(response)) {
            this.setState({ upgradeToPlusCost: response.data });
          } else {
            this.setState({ upgradeToPlusCost: null });
          }
        }
      );
    }
  }

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!this.state.enableSubmit) {
      return;
    }

    // If user has existing cards on file, but none are selected, and not showing new card form, return
    if (
      this.state.existingCreditCards.length > 0 &&
      !this.state.selectedCreditCardId &&
      !this.state.showUseNewCardForm
    ) {
      return;
    }

    // If no existing cards on file or showing new card form, and any of the fields are not entered, return
    if (
      (this.state.existingCreditCards.length === 0 ||
        this.state.showUseNewCardForm) &&
      (!this.state.nameOnCard ||
        !this.state.creditCardNumber ||
        !this.state.cv2 ||
        !this.state.expirationMonth ||
        !this.state.expirationYear)
    ) {
      return;
    }

    if (
      !this.state.showUseNewCardForm &&
      this.state.existingCreditCards.length > 0
    ) {
      this.submitData();
    } else if (this.state.postalCode.length === 0) {
      const serverError: FieldValidationError = {
        field: "PostalCode",
        errors: ["Zip/Postal code is required"],
      };
      this.setState({ errors: [serverError] });
      return;
    } else {
      if (this.state.selectedCountryShortCode) {
        this.setState(
          {
            validatedPostalCodeLocation: {
              country: this.state.selectedCountryShortCode,
              region: "",
              postalCode: this.state.postalCode,
            },
          },
          () => this.submitData()
        );
      } else {
        let postalCodeLocation = await PostalCodeService.getUSCanPostalCode(
          this.state.postalCode
        );

        if (postalCodeLocation === null) {
          const serverError: FieldValidationError = {
            field: "UnknownPostalCode",
            errors: ["Please select your country"],
          };
          this.setState({ showCountries: true, errors: [serverError] });
          return;
        } else {
          this.setState(
            { validatedPostalCodeLocation: postalCodeLocation },
            () => this.submitData()
          );
        }
      }
    }
  };

  submitData = async () => {
    try {
      this.setState({ enableSubmit: false });
      let costOfUpgradeForEcommerce: number | undefined;

      let response;

      if (this.state.selectedProPlanId === "AnnualPlus") {
        response = await ProSubscriptionService.upgradeToProPlus(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.validatedPostalCodeLocation
            ? this.state.postalCode
            : this.state.postalCode,
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
        costOfUpgradeForEcommerce = this.state.upgradeToPlusCost ?? undefined;
      } else if (this.state.selectedProPlanId === "AnnualPremium") {
        response = await ProSubscriptionService.upgradeToProPremium(
          !this.state.showUseNewCardForm ? this.state.selectedCreditCardId : "",
          this.state.nameOnCard,
          this.state.creditCardNumber,
          this.state.cv2,
          this.state.expirationMonth,
          this.state.expirationYear,
          this.state.validatedPostalCodeLocation
            ? this.state.postalCode
            : this.state.postalCode,
          this.state.validatedPostalCodeLocation?.region,
          this.state.validatedPostalCodeLocation?.country
        );
        costOfUpgradeForEcommerce = this.state.upgradeToPremiumCost;
      }

      if (ServerResponse.isSuccess<ApplicationUser>(response)) {
        this.props.handleUpdateUser(response.data);

        if (this.props.location.state) {
          this.redirectState = {
            returnUrl: (this.props.location.state as any).returnUrl,
          };
        }

        (this.redirectState as any).redirectedFromUpgradeTier = true;

        if (costOfUpgradeForEcommerce) {
          (this.redirectState as any).costOfUpgradeForEcommerce =
            costOfUpgradeForEcommerce;
        }

        this.setState({
          errors: [],
          redirectTo: {
            pathname: "/upgrade/confirmation",
            state: this.redirectState,
          },
        });
        return;
      } else if (
        ServerModelValidationResponse.isServerModelValidationResponse(response)
      ) {
        if (response.valid) {
          const serverError: FieldValidationError = {
            field: "",
            errors: ["An unknown error occurred. Please try again."],
          };
          this.setState({ errors: [serverError], enableSubmit: true });
        } else {
          this.setState({ errors: response.errors, enableSubmit: true });
        }
      } else if (ServerResponse.isError(response)) {
        const serverError: FieldValidationError = {
          field: "",
          errors: [response.message],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      } else {
        const serverError: FieldValidationError = {
          field: "",
          errors: ["An unknown error occurred. Please try again."],
        };
        this.setState({ errors: [serverError], enableSubmit: true });
      }
    } catch (errorResult) {
      const serverError: FieldValidationError = {
        field: "",
        errors: ["An unknown error occurred. Please try again."],
      };
      this.setState({ errors: [serverError], enableSubmit: true });
    }
  };

  updateCv2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      this.state.creditCardType &&
      this.state.creditCardType === CreditCardType.Amex
    ) {
      this.setState({ cv2: event.target.value.substr(0, 4) });
    } else {
      this.setState({ cv2: event.target.value.substr(0, 3) });
    }
  };

  updateCreditCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    let cardNumber = event.target.value.replace(/[a-zA-Z\-\ ]+/g, "");
    let newCardNumber = "";
    let creditCardType;

    // American Express XXXX XXXXXX XXXXX
    if (cardNumber[0] === "3") {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 6);
      }

      if (cardNumber.length > 10) {
        newCardNumber += " " + cardNumber.substr(10, 5);
      }

      creditCardType = CreditCardType.Amex;
    }

    // Visa XXXX XXXX XXXX XXXX
    // Mastercard XXXX XXXX XXXX XXXX
    // Discover XXXX XXXX XXXX XXXX
    else if (
      cardNumber[0] === "4" ||
      cardNumber[0] === "5" ||
      cardNumber[0] === "6"
    ) {
      newCardNumber = cardNumber.substr(0, 4);
      if (cardNumber.length > 4) {
        newCardNumber += " " + cardNumber.substr(4, 4);
      }

      if (cardNumber.length > 8) {
        newCardNumber += " " + cardNumber.substr(8, 4);
      }

      if (cardNumber.length > 12) {
        newCardNumber += " " + cardNumber.substr(12, 4);
      }

      if (cardNumber[0] === "4") {
        creditCardType = CreditCardType.Visa;
      } else if (cardNumber[0] === "5") {
        creditCardType = CreditCardType.Mastercard;
      } else if (cardNumber[0] === "6") {
        creditCardType = CreditCardType.Discover;
      }
    }

    this.setState({ creditCardNumber: newCardNumber, creditCardType });
  };

  getPlanPrice(plan: ProPlan) {
    if (plan.tier === ProSubscriptionTier.Plus) {
      return this.state.upgradeToPlusCost;
    } else if (plan.tier === ProSubscriptionTier.Premium) {
      return this.state.upgradeToPremiumCost;
    } else {
      return plan.price;
    }
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    if (this.state.loading) {
      return (
        <img
          className="sc-content-spinner"
          src="//i.cdn-sc.com/Logos/sc-full-spinner-gray.gif"
        />
      );
    }

    if (this.state.biennialUser) {
      return (
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="text-center py-5">
              <h2 className="mb-4">You're Grandfathered!</h2>
              <h6 className="mb-4">
                Please contact support at 1-(866)-899-4426 or{" "}
                <a href={`${this.props.config.mainSiteUrl}/contactus`}>
                  Contact Us
                </a>{" "}
                and we'll set you up on one of our current PRO plans.
              </h6>
              <button className="btn btn-secondary ml-auto mr-auto d-block">
                <a href={this.props.config.mainSiteUrl} className="buttonLink">
                  RETURN TO SERMONCENTRAL
                </a>
              </button>
            </div>
          </div>
        </div>
      );
    }

    const selectedProPlan = this.state.proPlans.find(
      (p) => p.id === this.state.selectedProPlanId
    );
    const defaultCard = this.state.existingCreditCards.find(
      (cc) => cc.useCardByDefault
    );
    const upgradePriceEstimateFailed =
      this.state.upgradeToPlusCost === null ||
      this.state.upgradeToPremiumCost === null;

    return (
      <div className="py-5">
        {FieldValidationError.hasGenericError(this.state.errors) && (
          <div
            className="alert alert-danger"
            role="alert"
            dangerouslySetInnerHTML={{
              __html: FieldValidationError.getGenericErrorSummary(
                this.state.errors
              ),
            }}
          />
        )}

        <div className="row">
          <div className="col-lg-8 order-12 order-lg-1">
            <form method="post" onSubmit={this.handleFormSubmit}>
              <div className="card mb-3">
                <div className="card-header primary-card-header">
                  <h5 className="text-white">Billing Info</h5>
                </div>
                <div className="card-body">
                  {this.state.existingCreditCards.length > 0 &&
                    !this.state.showUseNewCardForm && (
                      <>
                        <div className="mb-3">
                          <a
                            href="#"
                            className="float-right"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ showUseNewCardForm: true });
                            }}
                          >
                            use a new card
                          </a>
                          <label htmlFor="savedPaymentMethod">
                            Saved Payment Method
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control form-control-lg"
                              id="savedPaymentMethod"
                              value={this.state.selectedCreditCardId}
                              onChange={(e) =>
                                this.setState({
                                  selectedCreditCardId: e.target.value,
                                })
                              }
                            >
                              {this.state.existingCreditCards.map((cc) => (
                                <option key={cc.id} value={cc.id}>
                                  {cc.type} {cc.maskedNumber} (expires:{" "}
                                  {cc.expirationMonth}/{cc.expirationYear})
                                </option>
                              ))}
                            </select>
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "SavedPaymentMethod"
                              ),
                            }}
                          />
                        </div>
                        {this.state.selectedCreditCardId &&
                          defaultCard &&
                          this.state.selectedCreditCardId !==
                            defaultCard.id && (
                            <div className="alert alert-info mb-3" role="alert">
                              Your default card will be updated to the card you
                              selected.
                            </div>
                          )}
                      </>
                    )}

                  {(this.state.existingCreditCards.length === 0 ||
                    this.state.showUseNewCardForm) && (
                    <>
                      {this.state.existingCreditCards.length > 0 && (
                        <a
                          href="#"
                          className="float-right"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showUseNewCardForm: false });
                          }}
                        >
                          use an existing card
                        </a>
                      )}
                      <div className="mb-3">
                        <label htmlFor="nameOnCard">Name on card</label>
                        <input
                          type="text"
                          className={
                            FieldValidationError.isFieldInError(
                              this.state.errors,
                              "CreditCardName"
                            )
                              ? "form-control-lg form-control is-invalid"
                              : "form-control-lg form-control"
                          }
                          id="nameOnCard"
                          autoComplete="cc-name"
                          required
                          value={this.state.nameOnCard}
                          onChange={(e) =>
                            this.setState({ nameOnCard: e.target.value })
                          }
                        />
                        <div
                          className="invalid-feedback"
                          dangerouslySetInnerHTML={{
                            __html: FieldValidationError.getFieldErrorSummary(
                              this.state.errors,
                              "CreditCardName"
                            ),
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="creditCardNumber">
                          Credit card number
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardNumber"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="creditCardNumber"
                            autoComplete="cc-number"
                            required
                            value={this.state.creditCardNumber}
                            onChange={this.updateCreditCard}
                          />
                          <div className="input-group-append">
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Amex) && (
                              <span className="input-group-text cc-amex d-none d-sm-flex">
                                <FontAwesomeIcon icon={["fab", "cc-amex"]} />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Discover) && (
                              <span className="input-group-text cc-discover d-none d-sm-flex">
                                <FontAwesomeIcon
                                  icon={["fab", "cc-discover"]}
                                />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Mastercard) && (
                              <span className="input-group-text cc-mastercard d-none d-sm-flex">
                                <FontAwesomeIcon
                                  icon={["fab", "cc-mastercard"]}
                                />
                              </span>
                            )}
                            {(!this.state.creditCardType ||
                              this.state.creditCardType ===
                                CreditCardType.Visa) && (
                              <span className="input-group-text cc-visa d-none d-sm-flex">
                                <FontAwesomeIcon icon={["fab", "cc-visa"]} />
                              </span>
                            )}
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardNumber"
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="cv2">CVC/CVV</label>
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardSecurityCode"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="cv2"
                            autoComplete="cc-csc"
                            required
                            value={this.state.cv2}
                            onChange={this.updateCv2}
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardSecurityCode"
                              ),
                            }}
                          />
                        </div>
                        <div className="d-none d-md-block col-md-6 mb-3">
                          <p className="small pt-5">
                            The 3 or 4 digits near the signature strip
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="expirationMonth">
                            Expiration Date
                          </label>
                          <div className="input-group">
                            <select
                              className="form-control form-control-lg"
                              id="expirationMonth"
                              autoComplete="cc-exp-month"
                              value={this.state.expirationMonth}
                              onChange={(e) =>
                                this.setState({
                                  expirationMonth: parseInt(e.target.value, 10),
                                })
                              }
                            >
                              {[...Array(12).keys()].map((i) => {
                                return (
                                  <option key={i + 1} value={i + 1}>
                                    {i + 1}
                                  </option>
                                );
                              })}
                            </select>
                            <select
                              className="form-control form-control-lg"
                              id="expirationYear"
                              autoComplete="cc-exp-year"
                              value={this.state.expirationYear}
                              onChange={(e) =>
                                this.setState({
                                  expirationYear: parseInt(e.target.value, 10),
                                })
                              }
                            >
                              {[...Array(10).keys()].map((i) => {
                                var year = new Date().getFullYear();
                                return (
                                  <option key={i + year} value={i + year}>
                                    {i + year}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardExpirationMonth"
                              ),
                            }}
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardExpirationYear"
                              ),
                            }}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label htmlFor="postalCode">ZIP / Postal code</label>
                          <input
                            type="text"
                            className={
                              FieldValidationError.isFieldInError(
                                this.state.errors,
                                "CreditCardPostalCode"
                              )
                                ? "form-control-lg form-control is-invalid"
                                : "form-control-lg form-control"
                            }
                            id="postalCode"
                            autoComplete="shipping postal-code"
                            value={this.state.postalCode}
                            onChange={(e) =>
                              this.setState({ postalCode: e.target.value })
                            }
                          />
                          <div
                            className="invalid-feedback"
                            dangerouslySetInnerHTML={{
                              __html: FieldValidationError.getFieldErrorSummary(
                                this.state.errors,
                                "CreditCardPostalCode"
                              ),
                            }}
                          />
                        </div>
                      </div>
                      {this.state.showCountries && (
                        <div className="row">
                          <div className="col-12 mb-3">
                            <div className="input-group input-soft-border">
                              <select
                                className="form-control form-control-lg select-soft"
                                id="country"
                                value={
                                  this.state.selectedCountryShortCode
                                    ? this.state.selectedCountryShortCode
                                    : ""
                                }
                                onChange={(e) =>
                                  this.setState({
                                    selectedCountryShortCode: e.target.value,
                                  })
                                }
                              >
                                <option value="">Select country...</option>
                                {this.state.countries.map((c) => (
                                  <option
                                    key={c.twoCharCode}
                                    value={c.threeCharCode}
                                  >
                                    {c.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {FieldValidationError.isFieldInError(
                              this.state.errors,
                              "UnknownPostalCode"
                            ) && (
                              <div
                                className="need-more-feedback"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    FieldValidationError.getFieldErrorSummary(
                                      this.state.errors,
                                      "UnknownPostalCode"
                                    ),
                                }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.existingCreditCards.length > 0 && (
                        <div className="alert alert-info mb-3" role="alert">
                          Your default card will be updated to the card you
                          enter.
                        </div>
                      )}
                    </>
                  )}
                  <button
                    className="btn btn-primary btn-block btn-lg mb-4"
                    type="submit"
                    disabled={
                      !this.state.enableSubmit || upgradePriceEstimateFailed
                    }
                  >
                    {this.state.enableSubmit && <>UPGRADE</>}
                    {!this.state.enableSubmit && (
                      <>
                        PLEASE WAIT...
                        <div
                          className="spinner-border spinner-border-sm ml-4 mb-1"
                          role="status"
                        />
                      </>
                    )}
                  </button>
                  {upgradePriceEstimateFailed && (
                    <p className="alert-danger">
                      We were unable to automatically upgrade your plan. Contact
                      our{" "}
                      <a href="https://sermoncentral.com/contactus?ref=UpgradePlanFailure">
                        customer service
                      </a>{" "}
                      team for help.
                    </p>
                  )}
                  <p className="small">
                    Please note that your subscription will renew automatically
                    unless you cancel prior to renewal. We may contact you with
                    important information regarding your PRO subscription.
                    Standard data fees and text messaging rates may apply based
                    on your plan with your mobile phone carrier.
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-4 order-1 order-lg-2">
            <div className="card mb-3">
              <div className="card-header secondary-card-header">
                <h5>
                  <span className="text-white">Upgrade your Subscription</span>
                </h5>
              </div>
              <div className="card-body">
                {this.props.user.proSubscription?.tier ===
                  ProSubscriptionTier.Basic && (
                  <p>
                    Upgrade your subscription from Pro Basic to Plus to access
                    unlimited media or to Premium to access unlimited media and
                    sermon kits!
                  </p>
                )}
                {this.props.user.proSubscription?.tier ===
                  ProSubscriptionTier.Plus &&
                  this.props.user.proSubscription.term ===
                    ProSubscriptionTerm.Monthly && (
                    <p>
                      Upgrade your subscription from Pro Plus Monthly to Plus
                      Annually to access unlimited media downloads or to Premium
                      to access unlimited media and sermon kits!
                    </p>
                  )}
                {this.props.user.proSubscription?.tier ===
                  ProSubscriptionTier.Plus &&
                  this.props.user.proSubscription.term ===
                    ProSubscriptionTerm.Annual && (
                    <p>
                      Upgrade your subscription from Pro Plus to Premium to
                      access sermon kits!
                    </p>
                  )}
                {this.state.proPlans.map((plan) => (
                  <div key={plan.id} className="form-check mb-2 plan-option">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="SelectedPlan"
                      id={plan.id}
                      checked={this.state.selectedProPlanId === plan.id}
                      onChange={() =>
                        this.setState({ selectedProPlanId: plan.id })
                      }
                      onClick={() =>
                        this.setState({ selectedProPlanId: plan.id })
                      }
                      disabled={upgradePriceEstimateFailed}
                    />
                    <label className="form-check-label" htmlFor={plan.id}>
                      <strong>{plan.name}</strong>&nbsp;
                      <span className="pro-description-secondary">
                        <span>
                          {this.getPlanPrice(plan) != null && (
                            <span>
                              ${this.getPlanPrice(plan)}/{plan.billingPeriod}
                              <br />
                            </span>
                          )}
                          {this.getPlanPrice(plan) === null && (
                            <p className="alert-danger">
                              An error occured when estimating your upgrade
                              price
                            </p>
                          )}
                          {this.getPlanPrice(plan) === undefined && (
                            <>
                              Loading price{" "}
                              <div className="spinner-border text-primary" />
                              <br />
                            </>
                          )}
                          {plan.postDescription}
                        </span>
                      </span>
                    </label>
                  </div>
                ))}
                {selectedProPlan && (
                  <p className="small mt-3">
                    Please note that your subscription will renew automatically
                    at ${selectedProPlan.price}/yr + tax unless you cancel prior
                    to renewal.
                  </p>
                )}
              </div>
            </div>

            <div className="d-none d-lg-block">
              <div className="row py-5 mt-3">
                <div className="col-sm-3 mt-1 p-1">
                  <img className="img-fluid" src={SSLLogo} />
                </div>
                <div className="col-sm-9">
                  <h6>SSL Secure Connection</h6>
                  <small>
                    This a secure transaction with all information sent from our
                    registration page to our secure servers encrypted for your
                    protection.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
